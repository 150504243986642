/* ©VishwaGauravIn */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

/* Warning: Repetitive props used */

.parent-modal {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 3;
  width: 20rem;
  height: 20rem;
  background-color: #444444;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  border-style: solid;
  border-color: #ededed;
  box-shadow: 0 0 0 0.5rem #346751;
  animation: fadeIn 0.2s;
}
.fullscreen-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #474747; /* rgb(90, 90, 90) */
  background-image: linear-gradient(90deg, #121212 0%, rgb(40, 44, 52) 72%);
  z-index: 9999;
  animation: fadeIn 1s;
}
.pseudo-modal {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 2;
  width: 25rem;
  height: 25rem;
  background-color: rgb(90, 90, 90);
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  animation: fadeIn 1s;
  box-shadow: 4px 4px #888888;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.modal-title {
  margin-left: -1rem;
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  margin-top: 24px;
  color: #ededed;
}
.modal-title-image {
  width: 30px;
  margin: -4px;
}
.modal-divider {
  color: #ededed;
  width: 10rem;
  margin: 0;
}
.modal-body-buttons {
  margin-top: 5px;
}
.modal-body-button {
  color: #ededed;
  font-size: 20px;
  border-style: solid;
  border-color: #ededed;
  background-color: transparent;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
  z-index: 4;
  box-shadow: -1px 0 2px #e8bd70;
}
.modal-body-button:hover {
  background-color: #346751;
  transform: scale(1.01);
}
.modal-body-button:active + .modal-body-button:focus,
.modal-body-button:checked + .modal-body-button:focus,
.modal-body-button.active:focus,
.modal-body-button:active:focus,
.show > .modal-body-button.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem #31644e;
}
.modal-body-button:focus {
  box-shadow: 0 0 0 0.25rem #31644e;
  background-color: #346751;
  transform: scale(1.01);
}
.modal-btn-2 {
  width: 16rem;
  height: 50px;
}
.modal-btn-3 {
  width: 15rem;
  height: 50px;
  margin-left: -1rem;
}
.modal-btn-4 {
  width: 12rem;
  height: 50px;
  margin-left: -2rem;
  /* border-radius: 10% 20% 95% 36% / 30% 30% 95% 90% ; */
  border-color: #e8bd70;
  /* font-style: oblique; */
  /* #E8BD70 #EDD185 */
  fill: #e8bd70;
  color: #e8bd70;
}
.modal-btn-4:hover {
  color: #222831;
  transition: 0.2s ease-in-out;
  background-color: #e8bd70;
}
.modal-btn-4:focus {
  color: #222831;
  transition: 0.2s ease-in-out;
  background-color: #e8bd70;
  box-shadow: 0 0 0 0.25rem #ffa600;
}
.modal-btn-4:active + .modal-btn-4:focus,
.modal-btn-4:checked + .modal-btn-4:focus,
.modal-btn-4.active:focus,
.modal-btn-4:active:focus,
.show > .modal-btn-4.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem #ffa600;
}

.modal-btn-5 {
  background-color: transparent;
  border-style: none;
  width: 7rem;
  height: 34px;
  margin-left: -5rem;
}
.modal-btn-501 {
  margin-left: 380px;
  margin-top: 50px;
}
.modal-btn-502 {
  margin-top: 290px;
  margin-left: 0px;
}
.modal-btn-5:hover {
  transform: scale(1.08);
}

/* For the sake of Responsiveness */
@media only screen and (max-width: 600px) {
  .parent-modal {
    width: 16rem;
    height: 18rem;
  }
  .pseudo-modal {
    width: 19rem;
    height: 21rem;
  }
  .modal-divider {
    width: 12rem;
  }
  .modal-body-button {
    margin-top: -10px;
    margin-bottom: 20px;
    font-size: 16px;
  }
  .modal-btn-2 {
    width: 13rem;
    height: 50px;
  }
  .modal-btn-3 {
    width: 12rem;
    height: 50px;
    margin-left: -0.7rem;
  }
  .modal-btn-4 {
    width: 8rem;
    height: 50px;
    margin-left: -2rem;
    font-size: 20px;
    border-bottom-right-radius: 120px ;
    border-top-left-radius: 120px;
    margin-top: -5px;
  }
  .modal-btn-5 {
    position: absolute;
    margin-left: -50px;
    margin-top: -20px;
    z-index: 9999999;
  }
  .modal-btn-501 {
    margin-left: 270px;
    margin-top: 40px;
  }
  .modal-btn-502 {
    margin-top: 350px;
    margin-left: 0px;
  }
}