/* FREE TO USE ~~ github.com/VishwaGauravIn */
.btnoff {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border-radius: 12rem;
    background-color: #121212;
    border: 2px solid orange;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    transition: background-color 0.5s ease, border-color 0.5s ease;
  }
  
  .btnoff:hover {
    width: fit-content;
    background-color: #000;
    border-color: #000;
  }
  .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: background-color 0.25s ease;
  }
  .btnoff:hover .icon {
    background-color: #121212;
  }
  
  .icon svg {
    width: 40px;
    height: 40px;
    fill: rgb(255, 217, 146);
    transition: transform 0.5s ease;
  }
  .btnoff:hover .icon svg {
    transform: scale(0.7);
    fill: #3671e9;
  }
  