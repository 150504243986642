@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

body{
    font-family: "Montserrat", sans-serif;
}
.fullscreen-form {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #474747;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), #12121230);
    z-index: 9999;
  }
.new-form-shape{
    width: 36vw;
    height: max-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 3;
    background-color: #141414;
    border-radius: 20px;
    border-style: hidden;
    border-color: #14141409;
    border-width: 0.25rem;
    /* transition: width 1s ease-in; */
    animation: newShapeAppear 0.4s;
    -webkit-box-shadow: -5px 10px 40px 10px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: -5px 10px 40px 10px rgba(0, 0, 0, 0.3);
    box-shadow: -5px 10px 40px 10px rgba(0, 0, 0, 0.3)
}
@keyframes newShapeAppear {
    0%{
        width: 0rem;
        height: 0rem;
    }
    100%{
        width: 36vw;
        height: 68vh;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        z-index: 3;
        background-color: #202020;
        border-radius: 20px;
    }
}
@keyframes appearLate {
    0%{
        opacity: 0;
    }
    95%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.appear-late{
    animation: appearLate 0.3s;
}
.close-circle{
    width: 18px;
    height: 18px;
    border-radius: 50px;
    float: right;
    margin-top: 12px;
    margin-right: 8px;
    animation: appearLate 0.3s;
}
.red{
    background-color: rgb(255, 61, 61);
    color: #ffffff8e;
    display: inline;
    font-size: 0px;
    vertical-align: middle;
    font-family: "Montserrat", sans-serif;
    transition: width 0.3s ease-in;
}
.red:hover{
    font-size: 12px;
    cursor:pointer;
}
.green{
    background-color: rgb(124, 207, 0);
}
.yellow{

    background-color: rgb(255, 255, 0);
}
.form-title{
    margin-top: 4px;
    margin-left: 20px;
    color: white;
    font-size: 26px;
}
.form-body{
    color: whitesmoke;
    margin-top: -15px;
}
.form-content{
    background-color: #202020;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    /* min-height: max-content; */
    margin-top: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
}
.form-input{
    width: 38vh;
    margin-bottom: 10px;
    height: 6vh;
    border-radius: 20px;
    background: #3a3a3a;
    border-color: white;
    text-align: center;
    color: #EEEEEE;
    font-size: larger;
    padding-left: 8px;
    padding-right: 8px;
    border-style: solid;
    box-shadow: 0px 0px 10px #000;
    outline: transparent;
}

.form-input:focus{
    width: 42vh;
    /* transform: scale(1.01); */
}
.form-input-description{
    height: 18vh;
    font-size: medium;
    font-style: italic;
    max-height: 18vh;
    min-height: 18vh;
    overflow: hidden;
    resize: none;
}
.form-input-description:focus{
    border-style: hidden;
    box-shadow: 0 0 0 2px white;
}
.form-submit-btn{
    display: block;
    margin-top: -2px;
    margin-bottom: 10px;
    background-color: #222831;
    color: #e8bd70;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    border-style: solid;
    border-color: #e8bd70;
    box-shadow: 0px 0px 10px #000;
}
.form-submit-btn:hover{
    color: #222831;
    transition: 0.2s ease-in-out;
    background-color: #e8bd70;
}
.form-submit-btn:focus{
    color: #222831;
    transition: 0.2s ease-in-out;
    background-color: #e8bd70;
    box-shadow: 0 0 0 0.25rem #ffa600;
}
.form-label{
    color: rgb(182, 182, 182);
    background-color: #3a3a3a88;
    width: max-content;
    padding-bottom: 6px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    margin-bottom: 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
/* For the sake of Responsiveness */
@media only screen and (max-width: 600px) {
    .new-form-shape{
        width: 80vw;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        z-index: 3;
        border-radius: 20px;
        border-style: hidden;
        border-color: #4e5d72;
        border-width: 0.25rem;
        animation: newShapeAppear 0.29s;
    }
    @keyframes newShapeAppear {
        0%{
            width: 0rem;
            height: 0rem;
        }
        100%{
            width: 80vw;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            z-index: 3;
            border-radius: 20px;
        }
    }
}