*{
    margin: 0;
    padding: 0;
}
.loaderVG{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #7f5a83;
    background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);
    z-index: 9999999;
}
.loader-svg{
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    position: fixed;
    fill: #e63e6d;
    width: 80px;
    height: 80px;
    background-color: #121212;
    padding: 10px;
    border-radius: 50px;
    z-index: -1;
    box-shadow: 0px 0px 10px #121212;
}
.bi-lightning-charge-fill{
    opacity: 1;
    animation: lighteningAnimationFill 1s;
    animation-iteration-count:infinite;

}
@keyframes lighteningAnimationFill {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}

.Loading-text{
    position: inherit;
    left: 50%;
    transform: translate(-50%);
    color: #12121286;
}