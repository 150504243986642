.list-main{
    width: 20rem;
    float: right;
    margin-right: 20px;
    margin-top: 9vh;
}
.top-tools{
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
}
.list-group-item{
    background-color: #ffffff2c;
    color: white;
    border-style: hidden;
}
.list-group-item:hover{
    animation: scaleLarge 0.5s;
    transform: scale(1.04);
    border-style: hidden;
    background-color: rgba(97, 97, 97, 0.314);
    
}
.list-no1{
    background-color: #ec716385 ;
}
.list-no2{
    background-color: #bb8fce80;
}
.list-no3{
    background-color:  #5dade281;
}
.list-no1:hover{
    background-color: #ec7163 ;
}
.list-no2:hover{
    background-color: #bb8fce;
}
.list-no3:hover{
    background-color: #5dade2;
}
@keyframes scaleLarge {
    0%{
        transform: scale(1.0);
    }
    100%{
        transform: scale(1.04);
    }
}
.list-img{
    border-radius: 50px;
    border-style: hidden;
}

.rounded-pill{
    background-color: #b42b51;
}

/* For the sake of Responsiveness */
@media only screen and (max-width: 1000px) {
    .list-main{
        position: relative;
        margin-top: 2vh;
        margin-right: 0px;
        margin-left: 0px;
        width: 90vw;
        float: none;
        left: 50%;
        transform: translate(-50%);
    }
    .list-group-item{
        display: inline-block;
        width: 90vw;
        color: white;
        border-style: hidden;
    }
}
a{
    text-decoration: none;
}
