@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

.navbar-custom-props {
  background-color: #121212;
  background-image: linear-gradient(90deg, #121212 0%, rgb(40, 44, 52) 72%);
}
.dropdown-menu-custom {
  background-color: #121212;
  border-style: solid;
  border-color: aliceblue;
}
.dropdown-item-custom {
  background-color: #121212;
  color: white;
}
.dropdown-item-custom:hover {
  cursor:pointer;
}
.btn-success-custom {
  background-color: #b42b51;
  border-style: none;
  animation: contribute-animation 2s infinite;
}
.btn-success-custom:hover {
  background-color: #e63e6d;
  border-color: #b42b51;
}
.btn-success-custom:focus {
  color: #fff;
  background-color: #e63e6d;
  border-color: #b42b51;
  box-shadow: 0 0 0 0.25rem #b42b51;
}
.nav-btn-contribute {
  width: 22px;
}
@keyframes contribute-animation {
  0%{margin-left: 0px;}
  50%{transform: scale(1.02);}
  100%{margin-left: 0px;}
}
.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem #b42b51;
}
.navbar-brand{
  font-size: 26px;
}
.nav-link:hover{
  cursor: pointer;
}
.btn-lst-tech{
  margin: 0;
  padding: 0;
  display: block;
  background-color: transparent;
  color: white;
  font-size: 32px;
  border-style: hidden;
}
.btn-lst-tech:hover .bi-search{
  transform: scale(1.2);
}
.bi-search{
  margin-top: -10px;
  transition: transform 0.2s ease-in;
}
.offcanvas-bottom{
  height: max-content;
  background-color: transparent;
  border-style: hidden;
}