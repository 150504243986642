/* Free to Use , githhub.com/VishwaGauravIn */


/* Basic */
::-webkit-scrollbar{
    background-color: transparent;
    width: 1em;
}

::-webkit-scrollbar-track{
    /* background-image: linear-gradient(90deg, rgb(40, 44, 52) 0%, #121212 72%); */
    background-color: #282c34;
}

/* default color, border color to match body bg and give some space*/
::-webkit-scrollbar-thumb{
    transform-style: preserve-3d;
    background-color: #ff2e6285;
    border-radius: 50px;
    border-color: #282c34;
    border-style: solid;
}

/* When you hover mouse on it */
::-webkit-scrollbar-thumb:hover{
    background-color: #ff2e62cc;
}

/* basically onClick */
::-webkit-scrollbar-thumb:active {
    background-color: #ff2e62f1;
}