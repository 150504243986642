@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
.form-modal-parent{
    position: fixed;
    z-index: 9999999;
    background-color: #1f1f1f;
    border-radius: 20px;
    width: 18rem;
    height: 10rem;
    padding: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.267);
    animation: fadeappear 0.4s;
}
.fullscreen-modal{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999999;
background-color: #7f5a83;
background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);

}
.float-right{
    float: right;
}
.form-modal-body-text{
    color: white;
    margin-top: 2.2rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}
@keyframes fadeappear {
    0%{opacity: 0;}
    100%{opacity: 1;}
}