@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
.bg-color-purple{
  background-color: #330867;
}
.text-color-white{
  color: white;
}
body{
  background-color: #121212;
  background-image: linear-gradient(90deg, #121212 0%, rgb(40, 44, 52) 72%);
  align-items: center;
}

.card{
    margin-top: 1vh;
    margin-bottom: 1vh;
    /* margin-left: 1vw;
    margin-right: 1vw; */
    background-color: transparent;
    color: white;
    border-radius: 3vh;
    width: 17rem;
}
/* creating zoom effect on mouse hover */
.card:hover{
  transform: scale(1.01);
  cursor: pointer;
}  
.card-body{
  background-color: rgba(0,0,0, 0.1);
  border-radius: 20px;
}
.main{
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 20px;
  margin-right: 20px;
  width: 17rem; /*17rem*/
}
/* To remove underlines from hyperlinks */
.urlLinkCard{
  text-decoration: none;
}
.big-box{
  max-width: 60rem;
  min-width: 0;
  margin-top: 2vh;
  margin-bottom: 2vh;
  /* margin-left: 14.5vw; */
  position: relative;
  left: 50%;
  transform: translate(-50%,0%);
}
.headingLogo{
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 75px;
}
.inline{
  display: inline-block;
}
.card-image{
  border-radius: 50px;
  border-width: 4px;
  border-style: solid;
  border-color: white;
  margin-bottom: 8px;
  background-color: white;
  width: 50px;
  height: 50px;
}
.card-image:hover{
  image-resolution: 54px 54px;
}
.card-title{
  font-family: 'Montserrat', sans-serif;
}
.card-text{
  color: rgba(255, 255, 255, 0.87);
}
#canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.3;
}
/* For the sake of Responsiveness */
@media only screen and (max-width: 600px) {
  .big-box{
    max-width: 60rem;
    min-width: 0;
    margin-top: 2vh;
    margin-bottom: 2vh;
    /* margin-left: 14.5vw; */
    /* position: relative; */
    display: inline-block;
    left: 15%;
    transform: translate(0%,0%);
  }
  .main{
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: 17rem; /*17rem*/
  }
}


/* Regressive animation zone */
.ani-appear{
  animation: appear 0.8s;
}

@keyframes appear {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}