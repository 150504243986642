.home-title{
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 7vw;
    color: white;
    width: max-content;
    max-width: 100vw;
    text-decoration: overline wavy #ff2e62f1;
}
.home-desc{
    font-family: "Montserrat", sans-serif;
    font-size: 2em;
    width: max-content;
    color: rgba(175, 175, 175, 0.774);
    margin-left: 2vw;
    width: 58vw;
}
.home-tagline{
    color: rgba(255, 255, 255, 0.781);
    color: #f4d03fde ;
    font-size: 1.75em;
    font-weight: 600;
}
.home-about{
    width: max-content;
    display: inline-block;
    margin-left: 4vw;
    margin-top: 2vh;
}
.home-features{
    margin-top: 20px;
    font-size: 32px;
    color: white;
    width: 58vw;
}
.home-feature{
    margin-bottom: 10px;
}
.text-center{
    margin-top: 20px;
    font-size: 3.5vh;
}
.blockquote{
    font-weight: 600;
    font-size: 5vh;
    color: #5DADE2;
    font-style: italic;
}
.blockquote-footer{
    color: white;
    opacity: 0.5;
}
.home-divider{
    color: white;
    max-width: 90vw;
    margin: 0;
}
.adjust-svg{
    margin-top: 7px;
}
.showdown-parent{
    width: 90vw;
}
.home-showdown{
    width: max-content;
    padding-right: 10px;
    border-radius: 50px;
    background-color: #ffffff2c;
    height: 56px;
    padding-bottom: 8px;
    max-height: max-content;
}
.showdown-element{
    font-family: "Montserrat", sans-serif;
    display: inline-block;
    height: 40px;
    width: 40px;
    border-radius: 100vw;
    margin-top: 8px;
    margin-left: 10px;
    vertical-align: middle;
    overflow: hidden;
    color: transparent;
}
.showdown-element:hover{
    cursor: pointer;
    /* width: 50px; */
    transform: scale(1.05);
    color: white;
    transition: transform 100ms ease;
}
.showdown-element:nth-child(1){
    background-color: #5DADE2;
}
.showdown-element:nth-child(2){
    background-color: #58D68D;
}
.showdown-element:nth-child(3){
    background-color: #ff2e62f1;
}
.showdown-element:nth-child(4){
    background-color: #EC7063;
}
.showdown-element:nth-child(5){
    background-color: #F4D03F;
}
.showdown-element:nth-child(6){
    background-color: #5499C7;
}
.showdown-element:nth-child(7){
    background-color: #E74C3C;
}
/* For the sake of Responsiveness */
@media only screen and (max-width: 1000px) {
    .home-title{
        font-size: 11.7vw;
        width: max-content;
        /* text-decoration: none; */
    }
    .home-desc{
        font-family: "Montserrat", sans-serif;
        font-size: 4vw;
        width: max-content;
        color: rgb(175, 175, 175);
        margin-left: 1vw;
        max-width: 90vw;
    }
    .home-about{
        width: max-content;
        margin-left: 4vw;
        margin-top: 1vh;
    }
    .home-features{
        position: relative;
        margin-top: 20px;
        font-size: 18px;
        color: white;
        width: 95vw;
    }
    .home-showdown{
        margin-top: 10px;
    }
    .blockquote{
        font-size: 4.5vh;
    }

  }

/* -------- Used earlier, may adjust later
  @media only screen and (min-device-width: 601px) and (max-device-width: 1000px) {
    .home-desc{
        font-size: 1.966em;
    }
    .home-about{
        margin-top: 2vh;
    }
    .blockquote{
        font-size: 5vw;
        width: 90vw;
    }
    .blockquote-footer{
        font-size: 30px;
        width: 80vw;
    }
    .home-showdown{
        height: max-content;
        width: max-content;
        margin-top: 10px;
    }
  }
*/
